// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-ru-tsx": () => import("./../../../src/pages/404.ru.tsx" /* webpackChunkName: "component---src-pages-404-ru-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bread-ru-tsx": () => import("./../../../src/pages/bread.ru.tsx" /* webpackChunkName: "component---src-pages-bread-ru-tsx" */),
  "component---src-pages-bread-tsx": () => import("./../../../src/pages/bread.tsx" /* webpackChunkName: "component---src-pages-bread-tsx" */),
  "component---src-pages-contacts-ru-tsx": () => import("./../../../src/pages/contacts.ru.tsx" /* webpackChunkName: "component---src-pages-contacts-ru-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-doors-ru-tsx": () => import("./../../../src/pages/doors.ru.tsx" /* webpackChunkName: "component---src-pages-doors-ru-tsx" */),
  "component---src-pages-doors-tsx": () => import("./../../../src/pages/doors.tsx" /* webpackChunkName: "component---src-pages-doors-tsx" */),
  "component---src-pages-fish-ru-tsx": () => import("./../../../src/pages/fish.ru.tsx" /* webpackChunkName: "component---src-pages-fish-ru-tsx" */),
  "component---src-pages-fish-tsx": () => import("./../../../src/pages/fish.tsx" /* webpackChunkName: "component---src-pages-fish-tsx" */),
  "component---src-pages-flowers-ru-tsx": () => import("./../../../src/pages/flowers.ru.tsx" /* webpackChunkName: "component---src-pages-flowers-ru-tsx" */),
  "component---src-pages-flowers-tsx": () => import("./../../../src/pages/flowers.tsx" /* webpackChunkName: "component---src-pages-flowers-tsx" */),
  "component---src-pages-form-ru-tsx": () => import("./../../../src/pages/form.ru.tsx" /* webpackChunkName: "component---src-pages-form-ru-tsx" */),
  "component---src-pages-form-tsx": () => import("./../../../src/pages/form.tsx" /* webpackChunkName: "component---src-pages-form-tsx" */),
  "component---src-pages-fruits-n-vegetables-ru-tsx": () => import("./../../../src/pages/fruits-n-vegetables.ru.tsx" /* webpackChunkName: "component---src-pages-fruits-n-vegetables-ru-tsx" */),
  "component---src-pages-fruits-n-vegetables-tsx": () => import("./../../../src/pages/fruits-n-vegetables.tsx" /* webpackChunkName: "component---src-pages-fruits-n-vegetables-tsx" */),
  "component---src-pages-index-ru-tsx": () => import("./../../../src/pages/index.ru.tsx" /* webpackChunkName: "component---src-pages-index-ru-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meat-ru-tsx": () => import("./../../../src/pages/meat.ru.tsx" /* webpackChunkName: "component---src-pages-meat-ru-tsx" */),
  "component---src-pages-meat-tsx": () => import("./../../../src/pages/meat.tsx" /* webpackChunkName: "component---src-pages-meat-tsx" */),
  "component---src-pages-milk-ru-tsx": () => import("./../../../src/pages/milk.ru.tsx" /* webpackChunkName: "component---src-pages-milk-ru-tsx" */),
  "component---src-pages-milk-tsx": () => import("./../../../src/pages/milk.tsx" /* webpackChunkName: "component---src-pages-milk-tsx" */),
  "component---src-pages-portfolio-ru-tsx": () => import("./../../../src/pages/portfolio.ru.tsx" /* webpackChunkName: "component---src-pages-portfolio-ru-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-products-ru-tsx": () => import("./../../../src/pages/products.ru.tsx" /* webpackChunkName: "component---src-pages-products-ru-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-templates-products-single-ru-tsx": () => import("./../../../src/templates/products-single.ru.tsx" /* webpackChunkName: "component---src-templates-products-single-ru-tsx" */),
  "component---src-templates-products-single-tsx": () => import("./../../../src/templates/products-single.tsx" /* webpackChunkName: "component---src-templates-products-single-tsx" */)
}

